<template>
  <div class="input-multi-image">
    <input
      type="file"
      ref="imagesInput"
      :accept="acceptFileType.join(', ')"
      style="display: none"
      multiple
      @change="handleFileUpload"
    />

    <div v-if="$slots.default" @click="$refs.imagesInput.click()">
      <slot></slot>
    </div>

    <fieldset
      v-else
      class="gallery-add rounded"
      @click="$refs.imagesInput.click()"
    >
      <v-btn :loading="loading" icon>
        <v-icon class="grey--text text--lighten-1" large>mdi-plus </v-icon>
      </v-btn>
    </fieldset>
  </div>
</template>

<script>
import { showModalAlertError } from "@/core/composables";

export default {
  props: {
    acceptFileType: {
      type: Array,
      default: () => ["image/*"]
    },
    maxFileSize: {
      type: Number,
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  emits: ["change"],

  data() {
    return {
      images: []
    };
  },
  methods: {
    validateFileSize(files) {
      return Array.from(files).filter(file =>
        this.maxFileSize ? file.size <= this.maxFileSize : true
      );
    },

    validateFileDimension(files) {
      return Promise.all(
        Array.from(files).map(file => {
          return new Promise(resolve => {
            const img = new Image();
            img.onload = () => {
              const isValid =
                (img.width === this.width && img.height === this.height) ||
                (!this.width && !this.height);

              resolve(isValid ? file : null);
            };

            img.src = URL.createObjectURL(file);
          });
        })
      ).then(files => files.filter(file => file));
    },

    async handleFileUpload(event) {
      const files = event.target.files;

      const validFilesSize = this.validateFileSize(files);
      const validFilesDimension = await this.validateFileDimension(
        validFilesSize
      );

      if (validFilesDimension.length < files.length) {
        if (validFilesDimension.length < files.length) {
          showModalAlertError(this, {
            title: "Cảnh báo",
            message: `Vui lòng tải lên hình ảnh có kích thước là ${
              this.width
            }x${this.height}px và kích cỡ nhỏ hơn ${this.maxFileSize /
              1024} KB.`
          });
        }
      }

      this.$emit("change", validFilesDimension);
      this.$refs.imagesInput.value = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.input-multi-image {
  .gallery-add {
    align-items: center;
    border: 2px dashed #e5e5e5;
    cursor: pointer;
    display: flex;
    justify-content: center;
    height: 120px !important;
    transition: all 0.4s ease;
    width: 120px !important;

    &:hover {
      background-color: #f9f9f9;
      border-color: #e0e0e0;
    }
  }
}
</style>
